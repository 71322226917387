<template>
    <div class="row mb-1">
        <div class="col-md-2">
            <b-button variant="info" block @click="decrease">
                <i class="fa fa-minus"></i>
            </b-button>
        </div>
        <div class="col-md-8">
            <b-form-datepicker 
                :value="value" 
                @input="onInput" 
                :min="min"
                locale="id-ID"
                :date-format-options="{
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }"
            ></b-form-datepicker>
        </div>
        <div class="col-md-2">
            <b-button variant="info" block @click="increase">
                <i class="fa fa-plus"></i>
            </b-button>
        </div>
    </div>
</template>

<script>
// import moment from 'moment'
export default {
    props: ['value', 'min'],
    methods: {
        onInput: function (value) {
            this.$emit('input', value)
            this.$emit('on-input', value)
        },
        decrease: function () {
            this.$emit('decrease')
        },
        increase: function () {
            this.$emit('increase')
        }
    }
}
</script>