<template>
    <div>
        <div class="mb-1">
            <b-button variant="info" block @click="days">
                Harian
            </b-button>
        </div>
        <div class="mb-1">
            <b-button variant="info" block @click="week">
                Mingguan
            </b-button>
        </div>
        <div class="mb-1">
            <b-button variant="info" block @click="month">
                Bulanan
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    methods: {
        days: function () {
            this.$emit('day-range')
        },
        week: function () {
            this.$emit('week-range')
        },
        month: function () {
            this.$emit('month-range')
        }
    }
}
</script>